import PropTypes from "prop-types"
import React, { useState } from "react"
import cx from "classnames"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  AppBar,
  Button,
  Drawer,
  makeStyles,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core"
import {
  Apps,
  AttachMoney,
  Description,
  LockOpen,
  Menu,
  Subject,
  Person,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
    },
  },
  logoDrawer: {
    margin: "8px",
  },
  button: {
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  item: {
    cursor: "pointer",
  },
  hideXs: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  hideSmUp: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}))

export const SimpleenLogoWide = props => {
  const data = useStaticQuery(graphql`
    query SimpleenLogoWide {
      simpleenLogo: file(relativePath: { eq: "simpleen-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 40
            quality: 100
            placeholder: TRACED_SVG
            layout: FIXED
          )
        }
      }
    }
  `)

  return (
    <GatsbyImage
      image={data.simpleenLogo.childImageSharp.gatsbyImageData}
      alt="Logo Simpleen - Home"
      {...props}
    />
  )
}

const Header = ({ compact, siteTitle, className }) => {
  const [toggle, setToggle] = useState(false)
  const classes = useStyles()

  return (
    <>
      <AppBar
        color="primary"
        position="static"
        elevation={0}
        className={className}
      >
        <Toolbar className={classes.toolbar}>
          <Button
            onClick={() => setToggle(!toggle)}
            style={{ padding: 0 }}
            className={classes.hideSmUp}
          >
            <Menu style={{ color: "white" }} />
          </Button>

          {/* Logo */}
          <Link to="/" style={{ lineHeight: 0 }} className={classes.hideXs}>
            <SimpleenLogoWide className={cx(classes.logo, classes.hideXs)} />
          </Link>

          {/* Main navigation for larger screens */}
          <Button
            component={Link}
            to="/blog"
            color="inherit"
            className={cx(classes.button, classes.hideXs)}
          >
            Blog
          </Button>

          <Button
            component={Link}
            to="/pricing"
            color="inherit"
            className={cx(classes.button, classes.hideXs)}
          >
            Pricing
          </Button>

          <Button
            component={Link}
            to="/documentation"
            color="inherit"
            className={cx(classes.button, classes.hideXs)}
          >
            Documentation
          </Button>

          <div className={classes.title} />

          {/*<Typography variant="h6" className={classes.title}></Typography>*/}

          <Button
            component={Link}
            to="/login"
            color="inherit"
            className={cx(classes.button, classes.hideXs)}
          >
            Log In
          </Button>

          <Button
            component={Link}
            to="/signup"
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Sign Up
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        transitionDuration={700}
        open={toggle}
        onClose={() => setToggle(false)}
        variant="temporary"
      >
        <AppBar
          color="primary"
          position="static"
          elevation={0}
          className={className}
        >
          <SimpleenLogoWide className={classes.logoDrawer} />
        </AppBar>
        <List component="nav">
          <ListItem
            onClick={() => setToggle(false)}
            button
            component={Link}
            to={"/"}
            className={classes.item}
          >
            <ListItemIcon>
              <Apps />
            </ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItem>

          <ListItem
            onClick={() => setToggle(false)}
            button
            component={Link}
            to={"/blog"}
            className={classes.item}
          >
            <ListItemIcon>
              <Subject />
            </ListItemIcon>
            <ListItemText primary="Blog" />
          </ListItem>

          <ListItem
            onClick={() => setToggle(false)}
            button
            component={Link}
            to={"/pricing"}
            className={classes.item}
          >
            <ListItemIcon>
              <AttachMoney />
            </ListItemIcon>
            <ListItemText primary="Pricing" />
          </ListItem>

          <ListItem
            onClick={() => setToggle(false)}
            button
            component={Link}
            to={"/documentation"}
            className={classes.item}
          >
            <ListItemIcon>
              <Description />
            </ListItemIcon>
            <ListItemText primary="Documentation" />
          </ListItem>

          <ListItem
            onClick={() => setToggle(false)}
            button
            component={Link}
            to={"/signup"}
            className={classes.item}
          >
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Sign up" />
          </ListItem>

          <ListItem
            onClick={() => setToggle(false)}
            button
            component={Link}
            to={"/login"}
            className={classes.item}
          >
            <ListItemIcon>
              <LockOpen />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  compact: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  compact: false,
}

export default Header

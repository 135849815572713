import React from "react"
import clx from "classnames"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { SimpleenLogoWide } from "./header"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    // sticky
    // minHeight: "50px",
    // marginBottom: "-50px"
  },

  footerTitle: {
    color: "#aaa",
    fontWeight: "bold",
    fontSize: "1rem",
    marginTop: "0",
    marginBottom: "0.5rem",
    display: "block",
    width: "100%",
    textTransform: "uppercase",
  },
  footerText: {
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      paddingRight: "70px",
      paddingLeft: "5px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: "90px",
    },
  },
  footerLogo: {
    marginTop: "0",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "40px",
      paddingTop: "40px",
    },
  },
  footerLink: {
    fontSize: "1rem",
    lineHeight: "2rem",

    textDecoration: "none",
    color: theme.palette.secondary.main, // "#fff",
    "&:hover": {
      fontWeight: "bold",
    },
  },
  swissMadeLogo: {
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}))

export const SwissMadeLogo = ({ style, className }) => {
  const data = useStaticQuery(graphql`
    query SwissMadeLogo {
      placeholderImage: file(relativePath: { eq: "SMS-Logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 25, layout: FIXED, placeholder: TRACED_SVG)
        }
      }
    }
  `)

  return (
    <GatsbyImage
      image={data.placeholderImage.childImageSharp.gatsbyImageData}
      alt="Logo Swiss Made Software"
      style={style}
      className={className}
    />
  )
}

const Footer = ({ withFlex, className }) => {
  const classes = useStyles()
  const rootClasses = clx(classes.root, className, {
    [classes.rootWithoutFlex]: withFlex,
  })

  return (
    <div className={rootClasses}>
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="space-between"
          className={classes.container}
        >
          <Grid item xs={12} md={4}>
            <SimpleenLogoWide className={classes.footerLogo} />

            <p className={classes.footerText}>
              Simpleen is a localization tool to translate i18n files from your
              App, Game or Software. <br />
              <br />
              Entirely independent & self-funded.
            </p>
          </Grid>
          <Grid item xs={12} md={3}>
            <p className={classes.footerTitle}>Resources</p>
            <Link to="/blog" className={classes.footerLink}>
              Blog
            </Link>
            <br />
            <Link to="/documentation" className={classes.footerLink}>
              Documentation
            </Link>
            <br />
            <Link
              to="/blog/icu-message-format-guide"
              className={classes.footerLink}
            >
              ICU Messages
            </Link>
            <br />
            <Link
              to="/blog/optimize-i18n-workflow"
              className={classes.footerLink}
            >
              I18n Workflow
            </Link>
            <br />

            <Link
              to="/documentation/api-reference"
              className={classes.footerLink}
            >
              API Documentation
            </Link>
            <br />
          </Grid>

          <Grid item xs={12} md={3}>
            <p className={classes.footerTitle}>Technologies</p>
            <Link to="/translate-angular-i18n" className={classes.footerLink}>
              Angular
            </Link>
            <br />
            <Link
              to="/translate-java-properties"
              className={classes.footerLink}
            >
              Java Properties & INI
            </Link>
            <br />
            <Link to="/translate-markdown" className={classes.footerLink}>
              Markdown
            </Link>
            <br />
            <Link to="/translate-php" className={classes.footerLink}>
              PHP
            </Link>
            <br />
            <Link to="/translate-rails-i18n" className={classes.footerLink}>
              Rails
            </Link>
            <br />
            <Link to="/translate-react-i18n" className={classes.footerLink}>
              React
            </Link>
            <br />
            <Link to="/translate-vue-i18n" className={classes.footerLink}>
              Vue
            </Link>
          </Grid>

          <Grid item xs={12} md={2}>
            <p className={classes.footerTitle}>Company</p>
            <Link to="/about" className={classes.footerLink}>
              About
            </Link>
            <br />
            <Link to="/contact" className={classes.footerLink}>
              Contact
            </Link>
            <br />
            <Link to="/privacy-policy" className={classes.footerLink}>
              Privacy Policy
            </Link>
            <br />
            <Link to="/terms-service" className={classes.footerLink}>
              Terms of Service
            </Link>
            <br />
            <Link to="/imprint" className={classes.footerLink}>
              Imprint
            </Link>
            <br />
            <a
              href="https://www.swissmadesoftware.org/de/home/companies/triple-w-team-ag/home.html"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className={classes.footerLink}
            >
              <SwissMadeLogo className={classes.swissMadeLogo} />
            </a>
          </Grid>
        </Grid>
      </Container>

      {/*
      <Container maxWidth="lg">
        <footer className={classes.footer}>


          <div className={classes.addressArea}>
            <p style={{ fontSize: "1rem" }}>
              triple w team AG <br />
              Burghaldenstrasse 29 <br />
              5027 Herznach - Switzerland
            </p>
          </div>
          <div className={classes.contactArea}>
            <a href="mailto:info@simpleen.io" className={classes.footerLink}>
              info@simpleen.io
            </a>
            <br />
            <a href="https://twteam.ch" className={classes.footerLink}>
              twteam.ch
            </a>
          </div>

          <div className={classes.swissMadeArea}>
            <br />
            <Link to="/privacy-policy" className={classes.footerLink}>
              Privacy Policy
            </Link>
            <br />
            <Link to="/terms-service" className={classes.footerLink}>
              Terms of Service
            </Link>
            <br />

            <a
              href="https://www.swissmadesoftware.org/de/home/companies/triple-w-team-ag/home.html"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className={classes.footerLink}
            >
              <SwissMadeLogo style={{ marginTop: "5px" }} />
            </a>
      </div>
        </footer>
      </Container>*/}
    </div>
  )
}

export default Footer
